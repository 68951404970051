import { Currency } from '@pancakeswap/sdk'
import { ChainId } from '@pancakeswap/chains'
import { BinanceIcon, TokenLogo } from '@pancakeswap/uikit'
import { useEffect, useMemo, useState } from 'react'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'
import { styled } from 'styled-components'
import { ASSET_CDN, MORALIS_API, MORALIS_API_KEY } from 'config/constants/endpoints'
import { useHttpLocations } from '@pancakeswap/hooks'
import axios from 'axios'
import getTokenLogoURL from '../../utils/getTokenLogoURL'

const StyledLogo = styled(TokenLogo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
`

interface LogoProps {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}

export function FiatLogo({ currency, size = '24px', style }: LogoProps) {
  return (
    <StyledLogo
      size={size}
      srcs={[`/images/currencies/${currency?.symbol?.toLowerCase()}.png`]}
      width={size}
      style={style}
    />
  )
}

const cachedMoralisMetadata = {}

function useMoralisLogo(currency: Currency) {
  const [metadata, setMetadata] = useState<any>()
  useEffect(() => {
    if (currency) {
      if (cachedMoralisMetadata[currency.wrapped.address])
        setMetadata(cachedMoralisMetadata[currency.wrapped.address])
      else {
        axios.get(`${MORALIS_API}/erc20/metadata`, {
          params: {
            chain: 'pulse',
            addresses: [currency.wrapped?.address]
          },
          headers: {
            'accept': 'application/json',
            'X-API-Key': MORALIS_API_KEY
          }
        }).then(({ data }) => {
          if (data?.[0]) {
            cachedMoralisMetadata[currency.wrapped.address] = data?.[0]
            setMetadata(data?.[0])
          }
        })
      }
    }
  }, [currency])
  if (currency?.isNative)
    return undefined
  return metadata?.logo
}

export default function CurrencyLogo({ currency, size = '24px', style }: LogoProps) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const logoMoralis = useMoralisLogo(currency)

  const srcs: string[] = useMemo(() => {
    if (currency?.isNative) return []

    if (currency?.isToken) {
      const tokenLogoURL = getTokenLogoURL(currency)

      if (currency instanceof WrappedTokenInfo) {
        if (!tokenLogoURL) return [...uriLocations]
        return [...uriLocations, tokenLogoURL, logoMoralis]
      }
      if (!tokenLogoURL) return []
      return [tokenLogoURL, logoMoralis]
    }
    return [logoMoralis]
  }, [currency, uriLocations, logoMoralis])

  if (currency?.isNative) {
    // if (currency.chainId === ChainId.BSC) {
    //   return <BinanceIcon width={size} style={style} />
    // }
    return (
      <StyledLogo size={size} srcs={[`${ASSET_CDN}/images/369/symbols/${currency.symbol.toLowerCase()}.png`]} width={size} style={style} />
    )
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
