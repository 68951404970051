import { ErrorBoundary as SErrorBoundary } from '@sentry/nextjs'
import Page from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import { Button, Text, NotFound, Flex, IconButton, CopyIcon, copyText } from '@pancakeswap/uikit'
import { useCallback } from 'react'
import ReactGA from "react-ga4";
// import { event } from "nextjs-google-analytics";
import { sendGTMEvent } from '@next/third-parties/google'

export function SentryErrorBoundary({ children }) {
  const { t } = useTranslation()
  const handleOnClick = useCallback(() => window.location.reload(), [])
  return (
    <SErrorBoundary
      beforeCapture={(scope) => {
        scope.setLevel('fatal')
      }}
      fallback={({ eventId, componentStack, error }) => {


        sendGTMEvent({
          event: 'Error 404',
          category: 'Errors',
          label: error.stack,
        })

        // ReactGA.event({
        //   category: "Errors",
        //   action: "Error",
        //   label: error.stack,
        // });

        // event("Error", {
        //   category: "Error",
        //   label: error.stack,
        // });

        return (
          <Page>
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              {/* <LogoIcon width="64px" mb="8px" /> */}
              <NotFound />
              <Text mb="16px">{t('Oops, something wrong.')}</Text>
              {eventId && (
                <Flex flexDirection="column" style={{ textAlign: 'center' }} mb="8px">
                  <Text>{t('Error Tracking Id')}</Text>
                  <Flex alignItems="center">
                    <Text>{eventId}</Text>
                    <IconButton variant="text" onClick={() => copyText(eventId)}>
                      <CopyIcon color="primary" width="24px" />
                    </IconButton>
                  </Flex>
                </Flex>
              )}
              <Button onClick={() => {
                window.location.href = "/";
              }}>{t('Click here to reset!')}</Button>
              {/* <Button onClick={handleOnClick}>{t('Click here to reset!')}</Button> */}
            </Flex>
            {process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' && (
              <pre>
                <code>{error?.message}</code>
                <code>{componentStack}</code>
              </pre>
            )}
          </Page>
        )
      }}
    >
      {children}
    </SErrorBoundary>
  )
}
