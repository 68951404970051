/* eslint-disable no-param-reassign */
import { gql, GraphQLClient } from 'graphql-request'
import { getMultiChainQueryEndPointWithStableSwapPulseX, subgraphTokenSymbol } from 'state/info/constant'
import { Block, PoolData } from 'state/info/types'
import { safeGetAddress } from 'utils'
import { getChangeForPeriod } from 'utils/getChangeForPeriod'
import { getLpFeesAndApr } from 'utils/getLpFeesAndApr'
import { getAmountChange, getPercentChange } from 'views/Info/utils/infoDataHelpers'
import axios from 'axios'
import { STABLESWAP_SUBGRAPH_CLIENT } from 'config/constants/endpoints'
import {
  MultiChainName,
  getMultiChainQueryEndPointWithStableSwap
} from '../../constant'

interface PoolFields {
  id: string
  reserve0: string
  reserve1: string
  reserve2?: string
  reserveUSD: string
  volumeUSD: string
  volumeOutUSD?: string
  token0Price: string
  token1Price: string
  token2Price?: string
  timestamp: number
  token0?: {
    id: string
    symbol: string
    name: string
  }
  token1?: {
    id: string
    symbol: string
    name: string
  }
  token2?: {
    id: string
    symbol: string
    name: string
  }
}

export interface FormattedPoolFields
  extends Omit<
    PoolFields,
    'volumeUSD' | 'reserveUSD' | 'reserve0' | 'reserve1' | 'reserve2' | 'token0Price' | 'token1Price' | 'token2Price' | 'volumeOutUSD'
  > {
  volumeUSD: number
  reserveUSD: number
  reserve0: number
  reserve1: number
  reserve2?: number
  token0Price: number
  token1Price: number
  token2Price?: number
  volumeOutUSD?: number
}

interface PoolsQueryResponse {
  now: PoolFields[]
  oneDayAgo: PoolFields[]
  twoDaysAgo: PoolFields[]
  oneWeekAgo: PoolFields[]
  twoWeeksAgo: PoolFields[]
}

/**
 * Data for displaying pool tables (on multiple pages, used throughout the site)
 * Note: Don't try to refactor it to use variables, server throws error if blocks passed as undefined variable
 * only works if its hard-coded into query string
 */
const POOL_AT_BLOCK = (chainName: MultiChainName, block: number | null, pools: string[], isStableSwap: boolean = false) => {
  const blockString = block ? `block: {number: ${block}}` : ``
  const addressesString = `["${pools.join('","')}"]`

  if (isStableSwap)
    return `pairs(
      ${pools.length ? `where: { id_in: ${addressesString} }` : ''}    
      ${blockString}
      ${pools.length === 0 ? `
        orderBy: reserveETH
        orderDirection: desc
      ` : ''}
    ) {
      id
      reserve0
      reserve1
      reserve2
      reserveUSD
      volumeUSD
      volumeOutUSD
      token0Price
      token1Price
      token2Price
      timestamp
      token0 {
        id
        symbol
        name
      }
      token1 {
        id
        symbol
        name
      }
      token2 {
        id
        symbol
        name
      }
    }`
  return `pairs(
    ${pools.length ? `where: { id_in: ${addressesString} }` : ''}    
    ${blockString}
    ${pools.length === 0 ? `
        orderBy: trackedReserveETH
        orderDirection: desc
    ` : ''}
  ) {
    id
    reserve0
    reserve1
    reserveUSD
    volumeUSD
    token0Price
    token1Price
    timestamp
    token0 {
      id
      symbol
      name
    }
    token1 {
      id
      symbol
      name
    }
  }`
}

export const fetchPoolData = async (
  block24h: number,
  block48h: number,
  block7d: number,
  block14d: number,
  poolAddresses: string[],
  chainName: MultiChainName = 'PULSE',
  isStableSwap: boolean = false,
) => {
  try {
    const query = gql`
      query pools {
        now: ${POOL_AT_BLOCK(chainName, null, poolAddresses, isStableSwap)}
        oneDayAgo: ${POOL_AT_BLOCK(chainName, block24h, poolAddresses, isStableSwap)}
        twoDaysAgo: ${POOL_AT_BLOCK(chainName, block48h, poolAddresses, isStableSwap)}
        oneWeekAgo: ${POOL_AT_BLOCK(chainName, block7d, poolAddresses, isStableSwap)}
        twoWeeksAgo: ${POOL_AT_BLOCK(chainName, block14d, poolAddresses, isStableSwap)}
      }
    `
    const data = await getMultiChainQueryEndPointWithStableSwap(chainName, isStableSwap).request<PoolsQueryResponse>(query)
    if (!data) {
      return { error: true }
    }

    return { data, error: false }
  } catch (error) {
    console.error('Failed to fetch pool data', error)
    return { error: true }
  }
}



export const fetchDerivedUSDPricePerAddress = async (address, chainName: MultiChainName = 'PULSE', isStableSwap: boolean = false) => {
  try {
    const query = gql`
      query GetDerivedUSDPrice($address: String!) {
        tokens(where: { id: $address }) {
          derivedUSD
        }
      }
    `;
    // Assuming getMultiChainQueryEndPointWithStableSwap is a function to get the GraphQL endpoint
    let data;
    if (address.toLowerCase() === "0xdac17f958d2ee523a2206206994597c13d831ec7") {
      data = await getMultiChainQueryEndPointWithStableSwapPulseX(chainName, isStableSwap).request(query, { address: address.toLowerCase() });
    } else {
      data = await getMultiChainQueryEndPointWithStableSwap(chainName, isStableSwap).request(query, { address: address.toLowerCase() });
    }

    if (data.tokens && data.tokens.length > 0) {
      return { price: parseFloat(data.tokens[0].derivedUSD), error: false };
    }
    throw new Error(`No data found for token: ${address}`);

  } catch (error) {
    console.error(`Error fetching derived USD price for ${address}:`, error);
    return { error: true };
  }
}

// Transforms pools into "0xADDRESS: { ...PoolFields }" format and cast strings to numbers
export const parsePoolData = (pairs?: PoolFields[]) => {
  if (!pairs) {
    return {}
  }
  return pairs.reduce((accum: { [address: string]: FormattedPoolFields }, poolData) => {
    const { volumeUSD, reserveUSD, reserve0, reserve1, reserve2, token0Price, token1Price, token2Price, volumeOutUSD } = poolData
    accum[poolData.id.toLowerCase()] = {
      ...poolData,
      volumeUSD: parseFloat(volumeUSD),
      volumeOutUSD: volumeOutUSD && parseFloat(volumeOutUSD),
      reserveUSD: parseFloat(reserveUSD),
      reserve0: parseFloat(reserve0),
      reserve1: parseFloat(reserve1),
      reserve2: parseFloat(reserve2),
      token0Price: parseFloat(token0Price),
      token1Price: parseFloat(token1Price),
      token2Price: parseFloat(token2Price),
    }
    return accum
  }, {})
}

export const fetchAllPoolDataWithAddress = async (
  chainName: MultiChainName,
  poolAddresses: string[],
  blocks: Block[],
  isStableSwap: boolean = false,
) => {
  const [block24h, block48h, block7d, block14d] = blocks ?? []
  console.log('blocks', blocks)


  const { data, error } = await fetchPoolData(
    block24h.number,
    block48h.number,
    block7d.number,
    block14d.number,
    poolAddresses,
    chainName,
    isStableSwap
  );
  // const { data, error } = await fetchPoolDataNew(
  //   'latest',
  //   'block24h',
  //   'block48h',
  //   'block7d',
  //   'block14d',
  //   poolAddresses,
  //   chainName,
  //   isStableSwap
  // );

  const formattedPoolData = parsePoolData(data?.now);

  if (Object.keys(formattedPoolData).length === 0) {
    throw new Error('Pool data is empty');
  }

  const formattedPoolData24h = parsePoolData(data?.oneDayAgo);
  const formattedPoolData48h = parsePoolData(data?.twoDaysAgo);
  const formattedPoolData7d = parsePoolData(data?.oneWeekAgo);
  const formattedPoolData14d = parsePoolData(data?.twoWeeksAgo);

  const addresses = Object.keys(formattedPoolData);

  // Calculate data and format
  const formatted = addresses.reduce((accum: { [address: string]: { data: PoolData } }, address) => {
    // Undefined data is possible if pool is brand new and didn't exist one day ago or week ago.
    const current: FormattedPoolFields | undefined = formattedPoolData[address]
    const oneDay: FormattedPoolFields | undefined = formattedPoolData24h[address]
    const twoDays: FormattedPoolFields | undefined = formattedPoolData48h[address]
    const week: FormattedPoolFields | undefined = formattedPoolData7d[address]
    const twoWeeks: FormattedPoolFields | undefined = formattedPoolData14d[address]

    const [volumeUSD, volumeUSDChange] = getChangeForPeriod(current?.volumeUSD, oneDay?.volumeUSD, twoDays?.volumeUSD)
    const volumeOutUSD = current?.volumeOutUSD && getAmountChange(current?.volumeOutUSD, oneDay?.volumeOutUSD)
    const volumeOutUSDWeek = current?.volumeOutUSD && getAmountChange(current?.volumeOutUSD, week?.volumeOutUSD)
    const [volumeUSDWeek, volumeUSDChangeWeek] = getChangeForPeriod(
      current?.volumeUSD,
      week?.volumeUSD,
      twoWeeks?.volumeUSD,
    )

    const volumeUSD24H = current?.volumeUSD - oneDay?.volumeUSD

    const liquidityUSD = current ? current.reserveUSD : 0

    const liquidityUSDChange = getPercentChange(current?.reserveUSD, oneDay?.reserveUSD)

    const liquidityToken0 = current ? current.reserve0 : 0
    const liquidityToken1 = current ? current.reserve1 : 0
    const liquidityToken2 = current ? current.reserve2 : 0
    const timestamp = current.timestamp ?? 0

    const { totalFees24h, totalFees7d, lpFees24h, lpFees7d, lpApr7d } = getLpFeesAndApr(
      volumeUSD,
      volumeUSDWeek,
      liquidityUSD,
    )

    if (current) {
      accum[address] = {
        data: {
          address,
          token0: {
            address: current?.token0?.id ?? '',
            name: current?.token0?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token0?.id)] ?? current?.token0?.symbol ?? '',
          },
          token1: {
            address: current?.token1?.id ?? '',
            name: current?.token1?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token1?.id)] ?? current?.token1?.symbol ?? '',
          },
          token2: {
            address: current?.token2?.id ?? '',
            name: current?.token2?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token2?.id)] ?? current?.token2?.symbol ?? '',
          },
          timestamp,
          token0Price: current.token0Price,
          token1Price: current.token1Price,
          token2Price: current.token2Price,
          volumeUSD,
          volumeUSD24H,
          volumeUSDChange,
          volumeUSDWeek,
          volumeUSDChangeWeek,
          totalFees24h,
          totalFees7d,
          lpFees24h,
          lpFees7d,
          lpApr7d,
          liquidityUSD,
          liquidityUSDChange,
          liquidityToken0,
          liquidityToken1,
          liquidityToken2,
          volumeOutUSD,
          volumeOutUSDWeek,
        },
      }
    }

    return accum
  }, {})
  return formatted









  // const response = await axios.post(`${ API_URL } / pooldata`, {
  //   addresses,
  //   formattedPoolData,
  //   formattedPoolData24h,
  //   formattedPoolData48h,
  //   formattedPoolData7d,
  //   formattedPoolData14d,
  //   subgraphTokenSymbol,
  // });

  // return response.data;
};

// let lastError;
// let retryDelay = initialRetryDelay;

// for (let attempt = 0; attempt < maxRetries; attempt++) {
//   try {
//     return fetchAndProcessData();
//   } catch (error) {
//     lastError = error;
//     console.error(`Error fetching pool data(attempt ${ attempt + 1}/${maxRetries}): `, error);

//     if (attempt < maxRetries - 1) {
//       retryDelay *= 2;
//       return retryWithDelay(chainName, poolAddresses, isStableSwap, maxRetries - 1, retryDelay);
//     }
//   }
// }

// throw lastError || new Error(`Failed to fetch pool data after ${ maxRetries } attempts`);


export const fetchAllPoolDataWithAddressOld = async (
  chainName: MultiChainName,
  poolAddresses: string[],
  isStableSwap: boolean = false,
  blocks: Block[]
) => {
  const [block24h, block48h, block7d, block14d] = blocks ?? []

  const { data } = await fetchPoolData(
    block24h.number,
    block48h.number,
    block7d.number,
    block14d.number,
    poolAddresses,
    chainName,
    isStableSwap,
  )

  const formattedPoolData = parsePoolData(data?.now)
  const formattedPoolData24h = parsePoolData(data?.oneDayAgo)
  const formattedPoolData48h = parsePoolData(data?.twoDaysAgo)
  const formattedPoolData7d = parsePoolData(data?.oneWeekAgo)
  const formattedPoolData14d = parsePoolData(data?.twoWeeksAgo)

  const addresses = Object.keys(formattedPoolData)

  // Calculate data and format
  const formatted = addresses.reduce((accum: { [address: string]: { data: PoolData } }, address) => {
    // Undefined data is possible if pool is brand new and didn't exist one day ago or week ago.
    const current: FormattedPoolFields | undefined = formattedPoolData[address]
    const oneDay: FormattedPoolFields | undefined = formattedPoolData24h[address]
    const twoDays: FormattedPoolFields | undefined = formattedPoolData48h[address]
    const week: FormattedPoolFields | undefined = formattedPoolData7d[address]
    const twoWeeks: FormattedPoolFields | undefined = formattedPoolData14d[address]

    const [volumeUSD, volumeUSDChange] = getChangeForPeriod(current?.volumeUSD, oneDay?.volumeUSD, twoDays?.volumeUSD)
    const volumeOutUSD = current?.volumeOutUSD && getAmountChange(current?.volumeOutUSD, oneDay?.volumeOutUSD)
    const volumeOutUSDWeek = current?.volumeOutUSD && getAmountChange(current?.volumeOutUSD, week?.volumeOutUSD)
    const [volumeUSDWeek, volumeUSDChangeWeek] = getChangeForPeriod(
      current?.volumeUSD,
      week?.volumeUSD,
      twoWeeks?.volumeUSD,
    )

    const volumeUSD24H = current?.volumeUSD - oneDay?.volumeUSD

    const liquidityUSD = current ? current.reserveUSD : 0

    const liquidityUSDChange = getPercentChange(current?.reserveUSD, oneDay?.reserveUSD)

    const liquidityToken0 = current ? current.reserve0 : 0
    const liquidityToken1 = current ? current.reserve1 : 0
    const liquidityToken2 = current ? current.reserve2 : 0
    const timestamp = current.timestamp ?? 0

    const { totalFees24h, totalFees7d, lpFees24h, lpFees7d, lpApr7d } = getLpFeesAndApr(
      volumeUSD,
      volumeUSDWeek,
      liquidityUSD,
    )

    if (current) {
      accum[address] = {
        data: {
          address,
          token0: {
            address: current?.token0?.id ?? '',
            name: current?.token0?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token0?.id)] ?? current?.token0?.symbol ?? '',
          },
          token1: {
            address: current?.token1?.id ?? '',
            name: current?.token1?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token1?.id)] ?? current?.token1?.symbol ?? '',
          },
          token2: {
            address: current?.token2?.id ?? '',
            name: current?.token2?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token2?.id)] ?? current?.token2?.symbol ?? '',
          },
          timestamp,
          token0Price: current.token0Price,
          token1Price: current.token1Price,
          token2Price: current.token2Price,
          volumeUSD,
          volumeUSD24H,
          volumeUSDChange,
          volumeUSDWeek,
          volumeUSDChangeWeek,
          totalFees24h,
          totalFees7d,
          lpFees24h,
          lpFees7d,
          lpApr7d,
          liquidityUSD,
          liquidityUSDChange,
          liquidityToken0,
          liquidityToken1,
          liquidityToken2,
          volumeOutUSD,
          volumeOutUSDWeek,
        },
      }
    }

    return accum
  }, {})
  return formatted
}

export const fetchAllPoolData = async (chainName: MultiChainName, blocks: Block[], isStableSwap: boolean = false) => {
  return fetchAllPoolDataWithAddress(chainName, [], blocks, isStableSwap)
}





