import { ChainId } from '@pancakeswap/chains'

export const GRAPH_API_PROFILE = 'https://api.thegraph.com/subgraphs/name/pancakeswap/profile'
export const GRAPH_API_PREDICTION_BNB = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction-v2'
export const GRAPH_API_PREDICTION_CAKE = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction-cake'

export const GRAPH_API_LOTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/lottery'
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = 'https://profile.pancakeswap.com'
export const API_NFT = 'https://nft.pancakeswap.com/api/v1'
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const GRAPH_API_POTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/pottery'
export const ONRAMP_API_BASE_URL = 'https://pcs-on-ramp-api.com'
export const TRANSAK_API_BASE_URL = 'https://api-stg.transak.com/api/v1'
export const MOONPAY_BASE_URL = 'https://api.moonpay.com'
export const MORALIS_API = `https://deep-index.moralis.io/api/v2.2`
/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const STATS_CLIENT = 'https://node.9inch.io/subgraphs/name/nineinch/stats'
export const INFO_CLIENT = 'https://proxy-worker-api.pancakeswap.com/bsc-exchange'

export const INFO_CLIENT_ETH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exhange-eth'
export const BLOCKS_CLIENT_ETH = 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks'
export const BLOCKS_CLIENT_PULSE = 'https://subgraph.9inch.io/subgraphs/name/pulsechain/blocks'

export const STABLESWAP_SUBGRAPH_CLIENT = 'https://subgraph.9inch.io/subgraphs/name/pulsechain/stable'
export const GRAPH_API_NFTMARKET = 'https://api.thegraph.com/subgraphs/name/pancakeswap/nft-market'
export const GRAPH_HEALTH = 'https://node.9inch.io/index-node/graphql'

export const TC_MOBOX_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v3'
export const TC_MOD_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v4'

export const BIT_QUERY = 'https://graphql.bitquery.io'

export const ACCESS_RISK_API = 'https://red.alert.pancakeswap.com/red-api'

export const CELER_API = 'https://api.celerscan.com/scan'

export const ORDERS_CLIENT_WITH_CHAIN = {
  [ChainId.ETHEREUM]: 'https://subgraph.9inch.io/subgraphs/name/ethereum/orders',
  [ChainId.PULSE]: 'https://subgraph.9inch.io/subgraphs/name/pulsechain/orders',
}

export const DCA_ORDERS_CLIENT_WITH_CHAIN = {
  [ChainId.PULSE]: 'https://subgraph.9inch.io/subgraphs/name/pulsechain/dca',
}

export const BLOCKS_CLIENT_WITH_CHAIN = {
  [ChainId.ETHEREUM]: 'https://subgraph.9inch.io/subgraphs/name/ethereum/blocks',
  [ChainId.PULSE]: 'https://subgraph.9inch.io/subgraphs/name/pulsechain/blocks',
}

export const ASSET_CDN = 'https://v2.9inch.io'

export const V3_SUBGRAPH_URLS = {
  [ChainId.ETHEREUM]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-eth',
  [ChainId.PULSE]: 'https://node.9inch.io/subgraphs/name/nineinch/exchange-v3-mainnet',
}

export const V2_SUBGRAPH_URLS = {
  [ChainId.ETHEREUM]: 'https://subgraph.9inch.io/subgraphs/name/ethereum/v2',
  [ChainId.PULSE]: 'https://node.9inch.io/subgraphs/name/exchange-pulsechain',
}

export const TRADING_REWARD_API = 'https://pancake-trading-fee-rebate-api.pancakeswap.com/api/v1'

export const QUOTING_API = `${process.env.NEXT_PUBLIC_QUOTING_API}/v0/quote`

export const FARMS_API = 'https://farms-api.pancakeswap.com'

export const MERCURYO_WIDGET_ID = process.env.NEXT_PUBLIC_MERCURYO_WIDGET_ID || '64d1f9f9-85ee-4558-8168-1dc0e7057ce6'

export const MOONPAY_API_KEY = process.env.NEXT_PUBLIC_MOONPAY_LIVE_KEY || 'pk_test_1Ibe44lMglFVL8COOYO7SEKnIBrzrp54'

export const TRANSAK_API_KEY = process.env.NEXT_PUBLIC_TRANSAK_LIVE_KEY || 'bf960e79-6d98-4fd0-823d-8409d290c346'

export const MORALIS_API_KEY = process.env.NEXT_PUBLIC_MORALIS_API_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjkxNDc5ZWE3LThjNzctNDQ1Ni1iYzAwLTdiZmQ5NTU0MDVhNiIsIm9yZ0lkIjoiNDI0NzExIiwidXNlcklkIjoiNDM2ODA2IiwidHlwZUlkIjoiMWEyMTk5ZDAtYTdjNS00NGQ3LTgyYzMtNjgwZTUyYTM3Y2UwIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MzYzNzEwODIsImV4cCI6NDg5MjEzMTA4Mn0.yDYErhzpyYIPLNzgllSWof2_22bbLveVlZV41Y2yifA'
// no need for extra public env
export const MERCURYO_WIDGET_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://sandbox-widget.mrcr.io/embed.2.0.js'
    : 'https://widget.mercuryo.io/embed.2.0.js'
