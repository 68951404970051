import {
  BLOCKS_CLIENT_ETH,
  BLOCKS_CLIENT_PULSE,
} from 'config/constants/endpoints'
import { stableClient, v2Clients } from 'utils/graphql'
import { GraphQLClient } from 'graphql-request'

import { ChainId } from '@pancakeswap/chains'
import {
  ETH_TOKEN_BLACKLIST,
  PCS_ETH_START,
  ETH_TOKEN_WHITELIST,
} from 'config/constants/info'
import { mainnet } from 'wagmi/chains'
import { pulse } from 'config/chains'
import mapValues from 'lodash/mapValues'

export type MultiChainName =
  | 'BSC'
  | 'ETH'
  | 'POLYGON_ZKEVM'
  | 'ZKSYNC'
  | 'ARB'
  | 'LINEA'
  | 'BASE'
  | 'OPBNB'
  | 'BLOCKSPOT_TESTNET'
  | 'PULSE'

export type MultiChainNameExtend = MultiChainName | 'BSC_TESTNET' | 'ZKSYNC_TESTNET'

export const multiChainName = {
  [ChainId.ETHEREUM]: 'ETH',
  [ChainId.PULSE]: 'PULSE',
}

export const multiChainShortName: Record<number, string> = {
}

export const multiChainQueryMainToken = {
  ETH: 'ETH',
  PULSE: 'PLS',
}

export const multiChainBlocksClient = {
  ETH: BLOCKS_CLIENT_ETH,
  PULSE: BLOCKS_CLIENT_PULSE,
}

export const multiChainStartTime = {
  ETH: PCS_ETH_START,
  PULSE: 1698393775,
}

export const multiChainBlockTime = {
  ETH: 1000,
  PULSECHAIN: 1000,
}

export const multiChainStartBlock = {
  ETH: 18437760,
  PULSE: 18672539,
}

export const multiChainId = {
  ETH: ChainId.ETHEREUM,
  PULSE: ChainId.PULSE,
}

export const multiChainPaths = {
  [ChainId.ETHEREUM]: '/eth',
  [ChainId.PULSE]: '/pulse',
}

export const multiChainQueryClient = {
  ETH: v2Clients[ChainId.ETHEREUM],
  PULSE: v2Clients[ChainId.PULSE],
}

export const multiChainScan = {
  ETH: mainnet.blockExplorers.etherscan.name,
  PULSE: pulse.blockExplorers.default.name,
}

export const multiChainTokenBlackList = mapValues(
  {
    ETH: ETH_TOKEN_BLACKLIST,
    PULSE: [],
  },
  (val) => val.map((address) => address.toLowerCase()),
)

export const multiChainTokenWhiteList = mapValues(
  {
    ETH: ETH_TOKEN_WHITELIST,
    PULSE: [],
  },
  (val) => val.map((address) => address.toLowerCase()),
)

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainNameExtend, isStableSwap: boolean = false): GraphQLClient => {
  if (isStableSwap) return stableClient
  return multiChainQueryClient[chainName]
}

export const getMultiChainQueryEndPointWithStableSwapPulseX = (chainName: MultiChainNameExtend, isStableSwap: boolean = false): GraphQLClient => {
  if (isStableSwap) return stableClient
  return new GraphQLClient("https://graph.pulsechain.com/subgraphs/name/pulsechain/pulsex")
}

// FIXME: this should be per chain
export const subgraphTokenName = {
  '0x738d96Caf7096659DB4C1aFbf1E1BDFD281f388C': 'Ankr Staked MATIC',
  '0x14016E85a25aeb13065688cAFB43044C2ef86784': 'True USD Old',
}

// FIXME: this should be per chain
export const subgraphTokenSymbol = {
  '0x14016E85a25aeb13065688cAFB43044C2ef86784': 'TUSDOLD',
}

export const checkIsStableSwap = () => window.location.href.includes('stableSwap')

export const ChainLinkSupportChains = [ChainId.BSC, ChainId.BSC_TESTNET, ChainId.PULSE]
